@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

* {
  padding   : 0;
  margin    : 0;
  box-sizing: border-box;
}

[data-theme="light"] {
  --primary-color  : #89B0AE;
  --secondary-color: #BEE3DB;
  --font-color     : #555B6E;
  --bg-color       : #FAF9F9;
  --heading-color  : #FFD6BA;
}

[data-theme="dark"] {
  --primary-color  : #9A97F3;
  --secondary-color: #818cab;
  --font-color     : #e1e1ff;
  --bg-color       : #264653;
  --heading-color  : #fefae0;
}

html,
body {
  font-family            : 'Montserrat', sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* this will make 1rem = 10px */
  font-size              : 62.5%;
  background-color       : var(--bg-color);
  color                  : var(--font-color);
}

a {
  text-decoration: none;
}

.header {
  height          : max(6vh, 6rem);
  background-color: var(--primary-color);
  color           : var(--font-color);
  box-shadow      : 0 0 2px 2px rgba(0, 0, 0, 0.3);
  display         : grid;
  place-items     : center;
  position        : fixed;
  top             : 0;
  width           : 100%;
  z-index         : 9999;
}

.app__content {
  margin-top: max(6vh, 6rem);
}

.container {
  width : min(92vw, 1440px);
  margin: auto;
}

.home {
  padding-bottom: 3rem;
}

.nav {
  display              : grid;
  grid-template-columns: repeat(3, auto);
  align-items          : center;
  justify-items        : space-between;
}

.nav__info {
  display    : flex;
  align-items: center;
  gap        : 0.5rem;
}

.nav__info__logo {
  height: 4rem;
  width : 4rem;
}

.nav__info__title {
  font-size: 2.4rem;
  color    : var(--heading-color);
}

.nav__searchbar {
  justify-self    : center;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  border-radius   : 3rem;
  height          : 4.5rem;
  background-color: var(--secondary-color);
  width           : 30rem;
}

.nav__searchbar__btn {
  outline    : none;
  border     : 0;
  background : transparent;
  padding    : 0.5rem;
  display    : flex;
  align-items: center;
  cursor     : pointer;
}

.search-logo {
  height          : 3.6rem;
  width           : 3.6rem;
  border-radius   : 50%;
  background-color: white;
  padding         : 0.5rem;
}

.nav__searchbar__input {
  width     : 100%;
  min-width : 20rem;
  outline   : none;
  border    : 0;
  background: transparent;
  align-self: flex-end;
  padding   : 1rem 0 1rem 2rem;
  font-size : 2rem;
  color     : var(--font-color);
}

.nav__options {
  justify-self: flex-end;
  display     : flex;
  gap         : 1rem;
}

.theme-btn {
  height: 4rem;
  width : 4rem;
  cursor: pointer;
}

.favourite-btn {
  height: 4rem;
  width : 4rem;
  cursor: pointer;
}

.my-carousel {
  padding: 2rem 0;
}

.my-carousel-status {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.heading-1 {
  text-align: center;
  font-size : 3rem;
  padding   : 3rem 0;
}

.heading-2 {
  font-size: 2.4rem;
  padding  : 0.75rem 0;
}

.heading-3 {
  font-size: 2rem;
  padding  : 0.5rem 0;
}

.loader {
  height         : 100%;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding        : 3rem 0;
}

.loader img {
  height: 6.4rem;
  width : 6.4rem;
}

.error-msg {
  font-size  : 2rem;
  text-align : center;
  color      : red;
  padding-top: 2rem;
}

.carousel-item-center {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.movie-poster {
  justify-self    : center;
  cursor          : pointer;
  height          : 278px;
  width           : 185px;
  display         : grid;
  place-items     : center;
  background-color: var(--secondary-color);
  position        : relative;
  box-shadow      : 0 0 2px 1px rgba(0, 0, 0, 0.3);
}

.movie-poster>img {
  object-fit: cover;
  height    : inherit;
  width     : inherit;
}

.movie-poster>span {
  font-size : 2rem;
  padding   : 1rem;
  text-align: center;
}

.movie-poster:hover>.movie-poster-overlay {
  opacity: 1;
}

.movie-poster-overlay {
  position       : absolute;
  top            : 0;
  width          : 100%;
  height         : inherit;
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  width          : inherit;
  z-index        : 1;
  opacity        : 0;
  transition     : opacity 0.3s ease-in-out;
}

.movie-poster-overlay__delete__btn {
  padding   : 0.5rem;
  height    : 4.8rem;
  width     : 4.8rem;
  align-self: flex-end;
}

.movie-poster-overlay__title {
  font-size       : 1.4rem;
  padding         : 1rem;
  text-align      : center;
  width           : inherit;
  background-color: var(--primary-color);
}

.search-results,
.favourite-movies {
  padding-bottom: 5rem;
}

.search-results__content,
.favourite-movies__content {
  display              : grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  gap                  : 3rem;
}

.movie-details {
  min-height    : calc(100vh - max(6vh, 6rem));
  display       : flex;
  flex-direction: column;
  padding       : 2rem 0;
}

.movie-details__content {
  height         : 100%;
  width          : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.movie-details-card {
  display      : flex;
  flex         : 1;
  box-shadow   : 0 0 2px 1px rgba(0, 0, 0, 0.2);
  flex-wrap    : wrap;
  margin-bottom: 2rem;
}

.movie-details-card .poster {
  object-fit: cover;
}

.movie-details-card p {
  font-size: 1.4rem;
}

.movie-details-card .info {
  flex            : 1 32rem;
  background-color: var(--secondary-color);
  padding         : 2rem 3rem;
  display         : flex;
  flex-direction  : column;
  gap             : 2rem;
}

.movie-details-card .info__header {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.movie-details-card .info__header img {
  height: 3.6rem;
  width : 3.6rem;
  cursor: pointer;
}

.movie-details-card .details {
  display    : flex;
  align-items: center;
  flex-wrap  : wrap;
  gap        : 1rem 3rem;
}

.movie-details-card .play-trailer {
  display        : flex;
  justify-content: center;
  align-items    : center;
  gap            : 0.5rem;
  cursor         : pointer;
}

.movie-details-card .play-trailer img {
  height: 2rem;
  width : 2rem;
}

.zero-result {
  font-size : 1.5rem;
  text-align: center;
}

.trailer {
  position       : fixed;
  top            : 0;
  right          : 0;
  height         : 100%;
  width          : 100%;
  background     : rgba(0, 0, 0, 0.5);
  z-index        : 9999;
  display        : flex;
  justify-content: center;
  align-items    : center;
  cursor         : pointer;
}

.trailer-container {
  width          : min(90vw, 1200px);
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.react-youtube {
  position      : relative;
  width         : 100%;
  padding-bottom: 56.25%;
}

.react-youtube-iframe {
  position: absolute;
  width   : 100%;
  height  : 100%;
  left    : 0;
  top     : 0;
  border  : 0;
}

.trailer-error-container {
  display         : flex;
  padding         : 2rem;
  background-color: var(--secondary-color);
  height          : 15rem;
  width           : 30rem;
  display         : flex;
  align-items     : center;
  justify-content : center;
}

.movie-cast__content {
  display              : grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  gap                  : 2rem 1.5rem;
  font-size            : 1.5rem;
  justify-content      : center;
}

.movie-cast__card {
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: flex-end;
}

.movie-cast__card__content {
  width    : 100%;
  max-width: 28rem;
}

.movie-cast__card__content img {
  width     : 100%;
  object-fit: cover;
  display   : block;
}

.movie-cast__card__content span {
  padding      : 0.25rem;
  display      : block;
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
  background   : var(--secondary-color);
  width        : 100%;
  text-align   : center;
}

.page-not-found {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
}

.page-not-found__image {
  height: 70vh;
  width : 90vw;
}

.go-to-home-btn {
  outline         : none;
  border          : none;
  padding         : 0.75rem 3rem;
  font-size       : 2rem;
  border-radius   : 0.25rem;
  background-color: var(--secondary-color);
  color           : var(--font-color);
  cursor          : pointer;
}

/* 48em = 768px */
@media screen and (max-width: 48em) {
  .movie-details-card .poster {
    flex: 1;
  }
}

/* 42em = 672px screen size*/
@media screen and (max-width: 42em) {
  .header {
    height: 10rem;
  }

  .app__content {
    margin-top: 10rem;
  }

  .nav {
    grid-template-rows   : 2;
    grid-template-columns: repeat(2, auto);
    grid-gap             : 0.5rem;
  }

  .nav__info {
    grid-row: 1/1;
  }

  .nav__searchbar {
    grid-row   : 2/3;
    grid-column: 1/-1;
  }

  .movie-details-card .info {
    min-height: unset;
  }
}